import React from "react";
import Shade from "../../components/v2/Shade";

const ColourSwatch = ({ colour, name, pms, cmyk, rgb, hex, shade }) => {
  return (
    <div className={`colour-swatch ${colour}`}>
      {!shade && (
        <div className="inner-center">
          {name && !shade && <h3 className="title">{name}</h3>}
          <div className="copy">
            {pms && <p>PMS {pms}</p>}
            {cmyk && <p>CMYK {cmyk}</p>}
            {rgb && <p>RGB {rgb}</p>}
            {hex && <p>HEX #{hex}</p>}
          </div>
        </div>
      )}
      {shade && (
        <div className="shades">
          <div className="margin-aligner"></div>
          <Shade label={90} title={name} />
          <Shade label={80} />
          <Shade label={70} />
          <Shade label={60} />
          <Shade label={50} />
          <Shade label={40} />
          <Shade label={30} />
          <Shade label={20} />
          <Shade label={10} />
        </div>
      )}
    </div>
  );
};

export default ColourSwatch;
