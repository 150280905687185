import React from 'react';

import Layout from '../components/v2/Layout';
import ContentBlock from '../components/v2/ContentBlock';
import Hero from '../components/v2/Hero';
import PageSection from '../components/v2/PageSection';

import PrevNextNavFooter from '../components/v2/ui-blocks/PrevNextNavFooter/PrevNextNavFooter';

import ColourSwatch from '../components/v2/ColourSwatch';
import Checkerboard from '../components/v2/CheckerBoard';

import black from '../images/6. Colours/colours-black-1280x800.jpg';
import white from '../images/6. Colours/colours-white-1280x800.jpg';
import red from '../images/6. Colours/colours-red-1280x800.jpg';
import grey from '../images/6. Colours/colours-grey-1280x800.jpg';

import { pageLinks } from '../data/pageLinks.js';

const introText =
  'Colour is a powerful means of recognition, helping establish a clear identity and distinction for Toyota and our products. At the heart of our brand is Toyota Red. Red is the colour of energy. It is highly visible and conveys the Toyota spirit of excitement.';

const Colours = ({ location }) => {
  return (
    <Layout appLocation={location}>
      <Hero bgColor="#eb0a1e" text={introText} />
      <PageSection sectionId="brand-colours">
        <ContentBlock>
          <h2>BRAND COLOURS</h2>
          <div className="text">
            <p>
              Our colour palette is simple and impactful, comprising Toyota Red,
              white, black and grey. These are the colours of our identity, as
              expressed through our logos and our Visual Identity System. In
              addition to the colours within our photography, these are the only
              colours to be used when creating brand communications that sit
              within the Messaging Platform. Product Campaigns can use these
              colours but also have the option to use secondary colours, as
              specified later in these guidelines.
            </p>
          </div>
        </ContentBlock>
        <ColourSwatch
          name="Toyota Red"
          colour="red"
          pms="186C"
          cmyk="00 100 90 00"
          rgb="235 10 30"
          hex="EB0A1E"
        />
        <ColourSwatch
          name="White"
          colour="white"
          cmyk="00 00 00 00"
          rgb="255 255 255"
          hex="FFFFFF"
        />
        <ColourSwatch
          name="Black"
          colour="black"
          pms="Black C"
          cmyk="00 00 00 100"
          rgb="0 0 0"
          hex="000000"
        />

        <ColourSwatch name="Black Tint" colour="black-tint" shade={true} />
      </PageSection>
      <PageSection sectionId="colour-mode">
        <Checkerboard
          alt={true}
          title="Red"
          text="Red is considered the primary brand colour and is used sparingly to avoid competing with the visual impact of our logo."
          colour={red}
        />
        <Checkerboard
          title="Black"
          text="Black is generally used for typography only, but may be used elsewhere when deemed necessary."
          colour={black}
        />
        <Checkerboard
          alt={true}
          title="White"
          text="White space allows for information and product to breathe and often promotes greater visibility and impact."
          colour={white}
        />

        <Checkerboard
          title="Grey"
          text=" Grey may be used as a secondary colour for call-outs and to highlight CTAs."
          colour={grey}
        />
      </PageSection>
      <PrevNextNavFooter
        previousText="Previous"
        previousHeader="Typography"
        previousUrl={pageLinks.typography}
        nextText="Next"
        nextHeader="Tagline"
        nextUrl={pageLinks.tagline}
      />
    </Layout>
  );
};

export default Colours;
