import React from "react";

function rgbaToHex(r, g, b, a) {
  // Ensure values are in the valid range
  r = Math.min(255, Math.max(0, r));
  g = Math.min(255, Math.max(0, g));
  b = Math.min(255, Math.max(0, b));
  a = Math.min(1, Math.max(0, a));

  // Convert to hexadecimal
  const hexR = ('0' + Math.round(r).toString(16)).slice(-2);
  const hexG = ('0' + Math.round(g).toString(16)).slice(-2);
  const hexB = ('0' + Math.round(b).toString(16)).slice(-2);

  // Multiply alpha by 255 and convert to hexadecimal
  const hexA = ('0' + Math.round(a * 255).toString(16)).slice(-2);

  // Concatenate and return the result
  return '#' + hexR + hexG + hexB + hexA;
}

const Shade = ({ label, title }) => {
  // Convert to hex using provided function
  const hexColor = rgbaToHex(0, 0, 0, label / 100);

  return (
    <div
      className={`shade ${title && "large"}`}
      style={{ backgroundColor: hexColor }}
    >
      {title && <h3 className="title spacer">{title}</h3>}
      {!title && <div className="spacer">&nbsp;</div>}
      <div className="label">{label}%</div>
      <div className="spacer">&nbsp;</div>
      <div className="hex-value">{hexColor}</div>
    </div>
  );
};

export default Shade;
